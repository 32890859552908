$('.review-slider').slick({
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  fade: true,
  adaptiveHeight: true,
  appendArrows: $('.review .arrow')
})


$('.quiz__btn').click(function () {
  $(this)
    .closest('.quiz__btns')
    .find('.quiz__btn')
    .removeClass('is-active')
  $(this).addClass('is-active')
})

$('.start-quiz').click(e => {
  e.preventDefault()
  $('.quiz-wrap').addClass('is-active')
})
$('.js-close').click(e => {
  e.preventDefault()
  $('.quiz-wrap').addClass('fade-out')
  setTimeout(() => {
    $('.quiz-wrap').removeClass('is-active')
  }, 800)
  setTimeout(() => {
    $('.quiz-wrap').removeClass('fade-out')
  }, 1600)
})

let widthStep = 0
const w = $(window).outerWidth();
let currentAnswer = 0;
if (w > 430) {
  $('.quiz__slide').removeClass('is-active')
  $('.start-qw').addClass('is-active')
  $('.quiz__next').removeClass('hide')
}
$('.js-next').click(function (e) {
  e.preventDefault()
  const activeSlide = $('.quiz__slider > .is-active')
  const activeStep = $('.quiz__pages-item.is-active')
  const nextSlide = activeSlide.next()
  const nextStep = activeStep.next()
  const progress = $('.progress-bar')

  const width = `${100 / ($('.quiz__slide').length - 2)}`
  // alert(widthStep);
  if (w < 430) {
    if (activeSlide.index() === 0) {
      activeSlide.removeClass('is-active')
      nextSlide.addClass('is-active')
      $('.quiz__next').removeClass('hide')
      return false
    }
  }
  if ($(this).hasClass('right')) {
    ++currentAnswer
  }
  if (!(nextSlide.length === 0)) {
    activeSlide.removeClass('is-active')
    nextSlide.addClass('is-active')
    widthStep += +width
    progress.css({
      width: `${widthStep}%`
    })
  }
  if (!(nextStep.length === 0)) {
    activeStep.removeClass('is-active')
    nextStep.addClass('is-active')
  } else {
    let discount = 0;
    switch (currentAnswer) {
      case 1: discount = 5
        break;
      case 2: discount = 10
        break;
      case 3: discount = 15
        break;
      case 4: discount = 20
        break;
    }

    const orderItem = $('.bottles__item.one-bottle');

    if(discount!==0){

     orderItem.attr('data-off', `+${discount}% OFF`);
     orderItem.attr('data-info', `Enter Your Promo Code RPM${discount} at checkout`);

    }

 $('.js-discount').text(discount)
    $('.quiz__header-main').hide()
    $('.quiz__header-win').addClass('is-visible')
    $('.quiz__navigate').css({
      opacity: 0,
      visibility: 'hidden'
    })
  }
  if (nextStep.hasClass('last')) {
    $('.quiz__next').addClass('last-step')
  }

})

$('.copy').click(() => {
  const text = document.querySelector('.quiz__win-code span')
  const range = document.createRange()
  range.selectNode(text)
  window.getSelection().addRange(range)
  const successful = document.execCommand('copy')
  $('.quiz__win-code span').addClass('grey')
  window.getSelection().removeAllRanges()
})

document.querySelector('.js-close-order').addEventListener('click', e => {
  e.preventDefault()
  document.querySelector('body').classList.remove('order-show')
})
document.querySelectorAll('.js-show-order').forEach(btn => {
btn.addEventListener('click', e => {
  e.preventDefault()
  document.querySelector('body').classList.add('order-show')
})
});


$('#switch').change(function () {
  if ($(this).prop('checked')) {
    $('.light').addClass('on')
  } else {
    $('.light').removeClass('on')
  }
})

if (w > 430) {
  let controller = new ScrollMagic.Controller()
  let scene = new ScrollMagic.Scene({
    triggerElement: '.pills__img',
    triggerHook: 0.4
  }).addTo(controller)
  // .addIndicators();
  scene.on('start', function () {
    $('.dot').removeClass('is-hide')
  })
  scene.on('leave', function () {
    $('.dot').addClass('is-hide')
  })
  // $('.dot').click(function () {
  //   $('.dot').toggleClass('is-hide')
  // })
} else {
  $('.dot').removeClass('is-hide')
}

document.querySelector('.js-cancel').addEventListener('click', e => {
  e.preventDefault()

  document.querySelectorAll('.subscribe__card').forEach(card => {
    card.classList.toggle('is-active')
  })
  document.querySelectorAll('.order__bottle--item').forEach(orderItem => {
    orderItem.classList.toggle('is-active')
  })
})
document.querySelectorAll('.subscribe__card').forEach(card => {
  card.addEventListener('click', e => {
    e.preventDefault()
    if (e.currentTarget.classList.contains('is-active')) {
      return false
    }
    document.querySelectorAll('.subscribe__card').forEach(item => {
      item.classList.remove('is-active')
    })
    e.currentTarget.classList.add('is-active')

    document.querySelectorAll('.order__bottle--item').forEach(orderItem => {
      orderItem.classList.toggle('is-active')
    })
    document
      .querySelector('.order__bottle--one .bottles-cards')
      .classList.remove('is-hide')
    document
      .querySelector('.order__bottle--one .bottles-buy')
      .classList.remove('is-visible')
  })
})
document
  .querySelectorAll('.order__bottle--one .bottles__card')
  .forEach(card => {
    card.addEventListener('click', e => {
      e.preventDefault()
      if(card.classList.contains('is-active')){
        return false
      }
      const title = e.currentTarget.dataset.title
      const off = e.currentTarget.dataset.off
      const info = e.currentTarget.dataset.info
      const price = e.currentTarget.dataset.price
      const subtitle = e.currentTarget.dataset.subtitle
      const billed = e.currentTarget.dataset.billed
      const src = e.currentTarget.dataset.src
      const href = e.currentTarget.dataset.href

      document.querySelectorAll('.js-buy-title').forEach(item => {
        item.textContent = title
      })
      document.querySelector('.js-buy-subtitle').textContent = subtitle
      document.querySelector('.js-buy-img').src = src
      document.querySelector('.js-buy-billed').textContent = billed
      document.querySelector('.js-buy-price').textContent = price
      document.querySelector('.js-buy-off').textContent = off
      document.querySelector('.js-buy-info').textContent = info
      document.querySelector('.js-buy-btn').href = href
      document
        .querySelector('.order__bottle--one .bottles-cards')
        .classList.add('is-hide')
      document
        .querySelector('.order__bottle--one .bottles-buy')
        .classList.add('is-visible')
    })
  })

document.querySelectorAll('.js-info-cancel').forEach((btn) => {
  btn.addEventListener('click', e => {
    e.preventDefault()
    event.stopPropagation()
    document
      .querySelector('.order__bottle--one .bottles-cards')
      .classList.remove('is-hide')
    document
      .querySelector('.order__bottle--one .bottles-buy')
      .classList.remove('is-visible')
  })
})
function randomInteger(min, max) {
  let rand = min + Math.random() * (max - min);
  console.log(rand.toFixed(1));
  return +(rand.toFixed(1));
}
function animatePage() {
  const tl1 = new TimelineMax()
  const controller = new ScrollMagic.Controller()

  const title = $('.light .title')
  const lamp = $('.light__lamp')
  const descr = $('.light__descr')
  const word = $('.light__section-word')
  let numChars = null
  $('.light__section-word').each(function () {
    let tl4 = new TimelineMax()
    let letter = $(this).find('span')
    numChars = letter.length
    for (let i = 0; i < numChars; i++) {
      tl4.to(letter[i], 0.1, { opacity: 1 }, randomInteger(0.5, 1.1))
    }
    new ScrollMagic.Scene({
      triggerElement: this,
      triggerHook: 0.8
    })
      .setTween(tl4)
      // .addIndicators()
      .addTo(controller)
  })

  new ScrollMagic.Scene({
    triggerElement: '.light .title.show-desktop',
    triggerHook: 0.5
  })
    .setTween(
      TweenMax.to($('.light .title.show-desktop .grey'), 0.6, {
        autoAlpha: 1
      })
    )
    .addTo(controller)

  new ScrollMagic.Scene({
    triggerElement: '.light .title.show-desktop',
    triggerHook: 0.5
  })
    .setTween(
      TweenMax.to($('.light__lamp.show-desktop'), 0.6, {
        autoAlpha: 1
      })
    )
    .addTo(controller)

  new ScrollMagic.Scene({
    triggerElement: '.light .title.show-desktop',
    triggerHook: 0.5
  })
    .setTween(
      TweenMax.to($('.light__descr'), 0.8, {
        autoAlpha: 1
      })
    )
    .addTo(controller)

  // new ScrollMagic.Scene({
  //   triggerElement: '.title-m',
  //   triggerHook: 0.4
  // })
  //   .setTween(
  //     TweenMax.to($('.title-m .grey'), 0.6, {
  //       autoAlpha: 1
  //     })
  //   )
  //   .addTo(controller)

  new ScrollMagic.Scene({
    triggerElement: '.light .title.show-mobile',
    triggerHook: 0.4
  })
    .setTween(
      TweenMax.to($('.light__lamp.show-mobile'), 0.6, {
        autoAlpha: 1
      })
    )
    .addTo(controller)

  $('.feeling__list-item').each(function () {
    let controller = new ScrollMagic.Controller()
    new ScrollMagic.Scene({
      triggerElement: this,
      triggerHook: 0.8
    })
      .setClassToggle(this, 'is-visible')
      .addTo(controller)
  })

  new ScrollMagic.Scene({
    triggerElement: '.quality',
    triggerHook: 0.7
  })
    .setTween(
      TweenMax.to($('.quality__title'), 0.6, {
        autoAlpha: 1
      })
    )
    .addTo(controller)

  new ScrollMagic.Scene({
    triggerElement: '.quality',
    triggerHook: 0.65
  })
    .setTween(
      TweenMax.to($('.quality__img'), 0.9, {
        autoAlpha: 1
      })
    )
    .addTo(controller)
  new ScrollMagic.Scene({
    triggerElement: '.quality',
    triggerHook: 0.6
  })
    .setTween(

      TweenMax.to($('.quality__subtitle'), 0.6, {
        autoAlpha: 1
      })
    )
    .addTo(controller)
  new ScrollMagic.Scene({
    triggerElement: '.quality',
    triggerHook: 0.5
  })
    .setTween(
      TweenMax.to($('.quality__content'), 0.6, {
        autoAlpha: 1
      })
    )
    .addTo(controller)
}

function showModal(modal) {
  (modal).addClass('is-visible');
  $('.overlay').show();
}
$('.js-modal-close').click(function (e) {
  e.preventDefault()
  $('.modal').removeClass('is-visible');

  $('.overlay').hide();
});
function initSlider() {
  const slider = $('.js-modal-slider');
  $('.js-modal-ingr').click(function (e) {
    e.preventDefault();
    const num = +$(this).attr('data-modal') - 1;
    const name = $(this).attr('data-name');
    const modal = $('.js-' + name)
    showModal(modal)
    // $('.modal').addClass('is-visible');
    // $('.overlay').show();
    slider.slick({
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      fade: true,
      adaptiveHeight: true,
    })
    slider.slick('slickGoTo', num)
  });

  $('.js-ingr-close').click(function (e) {
    e.preventDefault()
    $('.modal').removeClass('is-visible');
    setTimeout(() => {
      slider.slick('destroy')
      $('.overlay').hide();
    }, 500)
  });
  $('.js-next-slide').click(function (e) {
    e.preventDefault();
    slider.slick('slickNext')
  })
}

$('.js-supplement-link').click(function (e) {
  e.preventDefault();

  const name = $(this).attr('data-name');
  const modal = $('.js-' + name)
  console.log(name, modal);
  showModal(modal);

})

initSlider()
animatePage()



